import { LatLng } from 'leaflet'

import {
    HookApiDistanceCoastAddress,
    HookApiDistanceCoastPoint,
    HookLeafletClickDouble,
    HookUrlParamPoint,
    HookUrlParamCenter,
    HookUrlParamMapOnly
} from 'Hook'
import { TypeApiDistanceCoastPoint, TypeApiDistanceCoastAddress, TypeUrlParamControl } from 'Type'
import { ComponentDisplayPanel, ComponentDivFill, ComponentDivMarginBottom, ComponentDivPositionAbsoluteRight,
    ComponentPointCoast, ComponentDivFlexGroup, ComponentDivFlexItem } from 'Component'
import {ModuleDisplayCoastAddress, ModuleDisplayCoastPoint, ModulePageLeafletMap, ModuleControlPagePoint } from 'Module'
import { UtilGeo } from 'Util'
import { BrowserView } from 'react-device-detect'

const PageHome = () => {
    const [point, setPoint] :TypeUrlParamControl<LatLng> = HookUrlParamPoint()
    const [_center, setCenter] :TypeUrlParamControl<LatLng> = HookUrlParamCenter()
    const pointCoast :TypeApiDistanceCoastPoint | undefined  = HookApiDistanceCoastPoint(point);
    const addressCoast :TypeApiDistanceCoastAddress | undefined  = HookApiDistanceCoastAddress(point);

    const reCenter = (latLng :LatLng) => {
        setPoint(latLng)
        setCenter(latLng)
    }

    const mapOnly :boolean = HookUrlParamMapOnly()

    return <ComponentDivFill>
        { (mapOnly) ? null : <BrowserView>
            <ComponentDivPositionAbsoluteRight top={98} margin={16} zIndex={1000} fullHeight>
              <ComponentDivFlexGroup fullHeight direction={'column'}>
                <ComponentDivFlexItem>
                  <ComponentDisplayPanel title={'Point'} width={240}>
                    <ModuleDisplayCoastPoint point={pointCoast}/>
                  </ComponentDisplayPanel>
                  <ComponentDivMarginBottom margin={10}/>
                </ComponentDivFlexItem>
                <ComponentDivFlexItem flexGrow={1} direction={'column'}>
                  <ComponentDisplayPanel title={'Address'} width={240} action={addressCoast && <ModuleControlPagePoint coord={point} target={UtilGeo.pointToLatLng(addressCoast.geometry)} setLatLng={reCenter}/>}>
                    <ModuleDisplayCoastAddress point={addressCoast}/>
                    <ModuleDisplayCoastPoint point={addressCoast}/>
                  </ComponentDisplayPanel>
                </ComponentDivFlexItem>
              </ComponentDivFlexGroup>
            </ComponentDivPositionAbsoluteRight>
        </BrowserView> }
        <ModulePageLeafletMap>
            <HookLeafletClickDouble setLatLng={setPoint}/>
            {(mapOnly) ? null : <ComponentPointCoast coord={point} distance={pointCoast?.distanceLine} distanceColor={'blue'} coast={pointCoast?.coast} coastColor={'red'}>
                { (addressCoast) ? <>
                    <ModuleDisplayCoastAddress point={addressCoast}/>
                    <ModuleDisplayCoastPoint point={addressCoast}/>
                </> : (pointCoast) ?
                    <ModuleDisplayCoastPoint point={pointCoast}/> : null }
            </ComponentPointCoast> }
        </ModulePageLeafletMap>
    </ComponentDivFill>
}

export default PageHome