import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import '@elastic/eui/dist/eui_theme_light.css'
import './App.css'

import { PageHome } from 'Page'
import {ComponentEuiBottomBar, ComponentEuiPage } from 'Component'
import {ModuleControlPageMap, ModuleHeaderPrimary, ModuleHeaderSecondary } from 'Module'
import {HookUrlParamMapOnly} from 'Hook'


function App() {

  const mapOnly :boolean = HookUrlParamMapOnly()

  return <div className="App">
      { (mapOnly) ? <BrowserRouter>
              <ComponentEuiPage headerHeight={0}>
                  <Routes>
                      <Route path={'/'} element={<PageHome/>} />
                  </Routes>
              </ComponentEuiPage>
          </BrowserRouter> :
          <BrowserRouter>
              <ModuleHeaderPrimary/>
              <ModuleHeaderSecondary/>
              <ComponentEuiPage headerHeight={148}>
                  <Routes>
                      <Route path={'/'} element={<PageHome/>} />
                  </Routes>
              </ComponentEuiPage>
              <ComponentEuiBottomBar affordForDisplacement padding={10}>{<ModuleControlPageMap/>}</ComponentEuiBottomBar>
          </BrowserRouter>
      }
    </div>

}

export default App;
