import { TypeUrlParam } from 'Type'

const mapOnlyParam :TypeUrlParam = 'map-only'

const HookUrlParamMapOnly = () :boolean => {

    const paramValue :string | undefined = new URLSearchParams(window.location.search).get(mapOnlyParam) || undefined

    return paramValue === 'true'
}

export default HookUrlParamMapOnly